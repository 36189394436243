// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

@charset "utf-8";

// Import a Google Font https://fonts.google.com/
// @import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

$shittools-blue: #413db7;
$shittools-gray: #494949;

$family-sans-serif: courier;
$grey-dark: $shittools-gray;
$grey-light: $shittools-gray;
$primary: $shittools-blue;
$link: $shittools-blue;

$enable-gradients: true;
$enable-shadows: true;

$body-color: #333;
$body-bg: #fff;
$border-radius: .4rem;
$success: #5265b3;

@import "../node_modules/bootstrap/scss/bootstrap";

//
// Custom styles
//

@import "icon-list";

body {
	padding: 1rem;
}
